import AnchorLink from '../common/links/anchor-link';
import cn from 'classnames';

export default function FourOFour({ className }: { className?: string }) {
    return (
        <div className={cn('flex h-screen w-screen flex-col items-center justify-center gap-4', className)}>
            <span className="h2-responsive">404 </span>
            <div className="flex flex-col items-center gap-2">
                <span className="h4-responsive text-black-800">Page Not Found</span>
                <span className="body2-responsive text-black-700 text-center">We’re sorry, we couldn’t find the page.</span>
            </div>
            <AnchorLink href="/" legacyBehavior>
                <p className="text-secondary-600">Go back home</p>
            </AnchorLink>
        </div>
    );
}
